<script setup lang="ts">
import type { BreadcrumbProps } from '#seo-utils/app/composables/useBreadcrumbItems'

const props = defineProps<{ overrides?: BreadcrumbProps['overrides'] }>()

const overridesRef = ref<BreadcrumbProps['overrides']>(props.overrides)

watch(
  () => props.overrides,
  (newOverrides) => {
    overridesRef.value = newOverrides
  },
  { deep: true },
)

const breadcrumbs = useBreadcrumbItems({ overrides: overridesRef.value })
</script>

<template>
  <div v-if="breadcrumbs.length" class="w-full py-s">
    <ContentContainer>
      <ol class="flex w-full list-none" aria-label="Brødsmulesti">
        <li v-for="crumb in breadcrumbs" :key="crumb.id" class="text-caption" :class="crumb.label !== 'Hjem' ? `before:content-['/'] before:p-2xs` : `before:content-['']  `">
          <NuxtLink :to="crumb.to" class="text-caption no-underline hover:underline active:no-underline">
            {{ crumb.label }}
          </NuxtLink>
        </li>
      </ol>
    </ContentContainer>
  </div>
</template>
